import '@/css/app.css'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/effect-fade'

import 'simple-lightbox/dist/simpleLightbox.min.css'

import { initializeKinesis } from "@amineyarman/kinesis"
import { init as CookieInit, Config } from 'cookie-though'

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
Alpine.plugin([focus, collapse])

import { CurrentOrder } from './global.model'

const main = async () => {
    document.addEventListener("DOMContentLoaded", () => {
        initializeKinesis();
    })

    linksNewWindow()
    cookieThough()
    newsletter()

    // Lazy-load modules based on selectors
    const dynamicComponents = [
        { selector: '.gsapBatchUpBase', method: 'gsapBatchUpBase' },
        { selector: '.gsapTextUp', method: 'gsapTextUp' },
        { selector: '.swiperDefault', method: 'swiperDefault' },
        { selector: '.swiperGallery', method: 'swiperGallery' },
        { selector: '.lightBoxGallery', method: 'simpleLightbox' },
    ] as const
    dynamicComponents.forEach(({ selector, method }) => {
        if (document.querySelector(selector)) {
            import('./components/defaults').then(module => {
                if (method in module) {
                    (module as any)[method]()
                }
            }).catch(error => console.error(`Error loading ${method}:`, error))
        }
    })

    // Alpine.js initialization
    document.addEventListener('alpine:init', () => {
        const jsOrderData = document.getElementById('js-order-data');
        const order = jsOrderData ? JSON.parse(jsOrderData.textContent || '[]') : null;

        /*
         * currentOrder stuff
         */
        Alpine.store('currentOrder', {
            reference: null,
            remark: null,

            machineType: null, //machineType ?? null,
            machineId: null,

            selectedDates: [],

            partner: null, //partner,
            partnerObj: [],

            location: null, //location,
            locationObj: [],

            products: [],

            orderId: order ? order[0]?.id : null,
            order: order ? order[0] : null,

            hasAssurance: false,
            privacy: false,
            manual: false,
            deposit: false,
            externalBuy: false,

            reset() {
                const currentOrder = Alpine.store('currentOrder') as CurrentOrder

                if (currentOrder) {
                    currentOrder.reference = null
                    currentOrder.remark = null
                    currentOrder.machineType = null //machineType
                    currentOrder.machineId = null
                    currentOrder.selectedDates = []
                    currentOrder.partner = null
                    currentOrder.partnerObj = []
                    currentOrder.location = null
                    currentOrder.locationObj = []
                    currentOrder.products = []
                    currentOrder.orderId = null
                    currentOrder.order = null
                    currentOrder.hasAssurance = false
                    currentOrder.privacy = false
                    currentOrder.manual = false
                    currentOrder.deposit = false
                    currentOrder.externalBuy = false

                    console.warn('currentOrder reset', currentOrder)
                } else {
                    console.error('currentOrder is not defined (reset)')
                }
            },
            resetDashboard() {
                const currentOrder = Alpine.store('currentOrder') as CurrentOrder

                if (currentOrder) {
                    currentOrder.partner = null;
                    currentOrder.partnerObj = [];
                    currentOrder.location = null;
                    currentOrder.locationObj = [];
                    currentOrder.machineId = null;
                    currentOrder.products = [];

                    console.warn('currentOrder resetDashboard', currentOrder)
                } else {
                    console.error('currentOrder is not defined (resetDashboard)')
                }
            },
        } as CurrentOrder)

        // Load existing data from localStorage (if any)
        //const savedOrder: CurrentOrder = JSON.parse(localStorage.getItem('currentOrder') || '{}')
        const savedOrder: CurrentOrder = JSON.parse(sessionStorage.getItem('currentOrder') || '{}');

        if (savedOrder) {
            const currentOrder = Alpine.store('currentOrder') as CurrentOrder

            currentOrder.reference = savedOrder.reference ?? currentOrder.reference
            currentOrder.remark = savedOrder.remark ?? currentOrder.remark
            currentOrder.machineType = savedOrder.machineType ?? currentOrder.machineType
            currentOrder.machineId = savedOrder.machineId ?? currentOrder.machineId
            currentOrder.selectedDates = savedOrder.selectedDates ?? currentOrder.selectedDates
            currentOrder.partner = savedOrder.partner ?? currentOrder.partner
            currentOrder.partnerObj = savedOrder.partnerObj ?? currentOrder.partnerObj
            currentOrder.location = savedOrder.location ?? currentOrder.location
            currentOrder.locationObj = savedOrder.locationObj ?? currentOrder.locationObj
            currentOrder.products = savedOrder.products ?? currentOrder.products
            currentOrder.orderId = savedOrder.orderId ?? currentOrder.orderId
            currentOrder.order = savedOrder.order ?? currentOrder.order
            currentOrder.hasAssurance = savedOrder.hasAssurance ?? currentOrder.hasAssurance
            currentOrder.privacy = savedOrder.privacy ?? currentOrder.privacy
            currentOrder.manual = savedOrder.manual ?? currentOrder.manual
            currentOrder.deposit = savedOrder.deposit ?? currentOrder.deposit
            currentOrder.externalBuy = savedOrder.externalBuy ?? currentOrder.externalBuy
        }

        // Watch for changes and save to localStorage
        Alpine.effect(() => {
            const currentOrder = Alpine.store('currentOrder') as CurrentOrder

            const dataToStore = {
                reference: currentOrder.reference,
                remark: currentOrder.remark,
                machineType: currentOrder.machineType,
                machineId: currentOrder.machineId,
                selectedDates: currentOrder.selectedDates,
                partner: currentOrder.partner,
                partnerObj: currentOrder.partnerObj,
                location: currentOrder.location,
                locationObj: currentOrder.locationObj,
                products: currentOrder.products,
                orderId: currentOrder.orderId,
                order: currentOrder.order,
                hasAssurance: currentOrder.hasAssurance,
                privacy: currentOrder.privacy,
                manual: currentOrder.manual,
                deposit: currentOrder.deposit,
                externalBuy: currentOrder.externalBuy,
            };
            //localStorage.setItem('currentOrder', JSON.stringify(dataToStore))
            sessionStorage.setItem('currentOrder', JSON.stringify(dataToStore))
        })

        Alpine.data('toastSystem', () => ({
            toastsList: [],

            init() {
                // Fetch flash messages from the DOM
                const flashMessages = JSON.parse(document.getElementById('flash-data').textContent || '{}');

                // Add flash messages to the toast list
                Object.keys(flashMessages).forEach((type) => {
                    if (type === 'error' || type === 'warning' || type === 'success') {
                        const messages = Array.isArray(flashMessages[type]) ? flashMessages[type] : [flashMessages[type]];

                        messages.forEach((message) => {
                            this.addToast(type, message);
                        });
                    }
                });

                // Listen for custom toast events
                window.addEventListener('toast', (event) => {
                    const { type, message } = (event as CustomEvent)?.detail;
                    this.addToast(type, message);
                });

                //this.addToast('success', 'Toasts are working!');
            },

            addToast(type: string, message: string) {
                // Add a toast with a unique ID for tracking
                const id = Date.now() + Math.random();
                this.toastsList.push({ id, type, message });

                // Automatically remove the toast after 5 seconds
                /*setTimeout(() => {
                    this.removeToast(id);
                }, 50000); // Auto-remove after 5 seconds*/
            },

            removeToast(id: number) {
                this.toastsList = this.toastsList.filter((toast) => toast.id !== id);
            },
        }))
    })

    const promises = [];

    const blocks = ['testimonials', 'spotlight', 'blog'];

    for (const block of blocks) {
        const blockElements = document.querySelectorAll(`.${block}`);

        const { init } = await import(`./blocks/${block}.ts`);

        blockElements.forEach((block) => {
            promises.push(init(block));
        });
    }

    const components = ['navMobile', 'js-customLocationsList', 'js-customOrderDashboard', 'js-customOrderProducts', 'js-customOrderSummary'];

    for (const comp of components) {
        const compElements = document.querySelectorAll(`.${comp}`);

        const { init } = await import(`./components/${comp.replace('js-','')}.ts`);

        compElements.forEach((comp) => {
            promises.push(init(comp));
        });
    }

    await Promise.all(promises).catch((e) => {
        console.error(e.message, e);
    });
};

const linksNewWindow = () => {
    const links = document.links;

    for (let i = 0; i < links.length; i++) {
        if (
            links[i].hostname !== window.location.hostname ||
            links[i].href.includes('uploads/files')
        ) {
            links[i].rel = 'noopener noreferrer';
            links[i].target = '_blank';
        }
    }
};

const cookieThough = () => {
    setTimeout(() => {
        if (window.translations?.cookieThough) {
            try {
                const config = window.translations.cookieThough as Config
                CookieInit(config)
            } catch (error) {
                console.error('Failed to parse cookie-though configuration:', error)
            }
        }
    }, 1000)




    /*setTimeout(() => {
        CookieInit(window.translations?.cookieThough);
    }, 1000);*/

    /*document.getElementById("re-consent").addEventListener("click", () => {
      CookieThough.show();
    });*/
};

const newsletter = () => {
    /*const newsletterButton = document.querySelector('a[href="#newsletter"]')

    if (newsletterButton) {
        newsletterButton.addEventListener('click', triggerNewsletterOpen);
    }

    function triggerNewsletterOpen() {
        document.getElementById('newsletter-button').click();
    }*/
    const newsletterButton = document.querySelector('a[href="#newsletter"]')
    const newsletterTriggerButton = document.getElementById('newsletter-button')

    if (newsletterButton && newsletterTriggerButton) {
        newsletterButton.addEventListener('click', () => {
            newsletterTriggerButton.click()
        })
    }
}

main().then(() => {
    console.info('Vite <3')
    Alpine.start()
});